@media (prefers-color-scheme: dark) {
    /* Works on Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: #333e5c #202a44;
    }
    
    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 12px;
    }
    
    *::-webkit-scrollbar-track {
        background: #333e5c;
    }
    
    *::-webkit-scrollbar-thumb {
        background-color: #202a44;
        border-radius: 4px;
    }
}

@media (prefers-color-scheme: light) {
    /* Works on Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: #e4f2ff #adc7d9;
    }
    
    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 12px;
    }
    
    *::-webkit-scrollbar-track {
        background: #e4f2ff;
    }
    
    *::-webkit-scrollbar-thumb {
        background-color: #adc7d9;
        border-radius: 4px;
    }
}